footer {
  width: 100%;
  text-align: center;
}


/* Container for footer columns */
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;

}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: #DDCAEF; /* Grey */
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer-top ul {
  padding-inline-start: 0px;
}

.footer-column {
  flex: 1;
  margin: 0 1rem;
  padding-left:5%;
  padding-right:5%;
  padding-top:0px;
  padding-bottom: 0px;
}

.footer-column .site-title {
  padding-top:0px;
}

/* Align first column to the left and second column to the right */
.footer-column:first-child {
  text-align: left;
}

.footer-column:last-child {
  text-align: left;
}

.footer-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows items to wrap if they can't fit in one line */
  background-color: transparent;
  padding: 1rem 0;
  width: 100%;
}

.footer-middle li {
  display: inline;
  margin: 0 1rem;
  list-style: none;
}

/* Style for the links */
.footer-middle a {
  text-decoration: none;
  color: #007bff;
}

.footer-middle a:hover {
  text-decoration: underline;
}

/* Footer bottom */
.footer-bottom {
  background-color: #DDCAEF;
  padding: 1rem 0;
  width: 100%;
}

.footer-bottom p {
  margin: 0px;
}

.footer-column li {
  text-decoration: none;
  list-style: none;
}

.contact-link {
  display: inline-block;
  margin-top: 8px;
  color:black;
  padding: 18px 22px;
  background-color: white;
  border-radius: 16px;
  text-decoration: none;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.contact-link:hover {
  background-color: whitesmoke;
  color:#723AA8;
  text-decoration: none;
}
/*
.footer-column button {
  border-radius: 16px;
  border: 0px;
  padding:24px 32px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 

}
.footer-column button h3 {
  margin:0px;
  font-size: 24px;
}
*/

.contact-info p:first-child {
   margin-top:0px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    align-items: center;
    text-align:center;
  }

  .footer-column {
    text-align: center;
    margin: 1rem 0;
  }

  /*.footer-column button {
    display: block; 
    margin: 1rem auto; 
  }*/

  .footer-column:first-child,
  .footer-column:last-child {
    text-align: center;
  }

  .footer-middle {
    flex-direction: column;
    gap: 1rem; /* Adds spacing between items on smaller screens */

  }

  .footer-middle li {
    display: block;
    /*margin: 0.5rem 0;*/
    margin:0px;
  }
}

/* Footer Links match text resizing of p */
.footer-middle li a {
  font-size: 20px;
  color:black;
}

@media (max-width: 700px) {
  .footer-middle li a {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .footer-middle li a {
    font-size: 16px;
  }
}

.social-media-links a {
  margin-right: 15px; /* Adjust the value for desired spacing */
}

.social-media-links a:last-child {
  margin-right: 0; /* Removes margin after the last icon */
}
