/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
  color:black;
}


.modal-content button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff; /* Primary color */
  color: white;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-content button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.modal-content button:active {
  transform: scale(0.98); /* Slightly shrink button on click */
}

.modal-content button.disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

