.media-manager-menu {
	display: flex;
	gap: 10px;
}


.media-manager-menu button {
	border: 2px solid black;
	border-radius: 5px;
	
}
.media-manager-menu button {
	border: 2px solid black;
	border-radius: 5px;
}

.media-manager-menu button:hover {
	text-decoration: underline;
}

.selected-images-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.selected-image-card input[type="numeric"] {
	width: 20px;
}

.selected-image-card .img-wrap {
	width: 50px;
	height:50px;
	margin: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border: 1px solid black;
}

.selected-image-card .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.selected-image-card .img-wrap p { 
	font-size: 8px;
}

.selected-image-card .selected-btns-wrap {
	display: flex;
	justify-content: center;
}
/*
.media-manager-wrap {
	min-width: 800px;
}

@media (max-width: 900px) {
	.media-manager-wrap {
		min-width: 600px;
	}
}

@media (max-width: 750px) {
	.media-manager-wrap {
		min-width: 500px;
	}
}

@media (max-width: 650px) {
	.media-manager-wrap {
		min-width: 300px;
	}
}
*/