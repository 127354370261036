/* Menu.css */
.burger-links h1{
  margin:0px;
}

.MenuWrap {
  /*float:right;*/
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.burger-icon {
  width: 100%;
}

.burger-open {
  color:blueviolet;
}

.burger-closed {
  color:black;
}

.full-menu .menu ul {
  display: flex;
  gap: 20px; /* Space between menu items */
  list-style: none; /* Remove default list styling */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}
/*.burger-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
*/

.burger-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  margin: 0;
}

.menu a {
  color: black; /*#61dafb;*/
  text-decoration: none;
  font-size: 18px;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.menu a:hover {
  /*
  background-color: #61dafb;
  color: #282c34;
  */
  background-color:rgba(221, 202, 239, 0.50);;
  color: #723AA8;
}

.drop-parent {
  position: relative;
}

.origin-right {
  right: 0;
}

.origin-left {
  left: 0;
}

.full-menu .menu ul.drop-list {
  display: none;
  position: absolute; /* Position it relative to the dropdown container */
  top: 100%; /* Place it directly below the parent menu item */
  /*left: 0;*/
  list-style: none; /* Remove default list styling */
  margin: 0;
  padding: 0;
  background-color: #fff; /* Background color of the dropdown */
  border: 1px solid #ddd; /* Border around the dropdown */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for a subtle floating effect */
  z-index: 1000; /* Ensure dropdown is above other content */
  text-align: center;
  width:350px;
}

.full-menu .menu li:hover ul.drop-list {
  display:block;
}

.full-menu .menu li:hover ul.drop-list a:hover { 
  /*color: #282c34; */
  background-color:rgba(221, 202, 239, 0.50);;
  color: #723AA8;
  width: 100%;
}


.full-menu .menu ul.drop-list li a {
  display: block;
  color: black; /*#61dafb;*/
  text-decoration: none;
  font-size: 18px;
  padding: 8px 0;
  border-radius: 0px; /*5px;*/
  transition: background-color 0.3s, color 0.3s;
  border-bottom: 1px solid lightgray;
}

.full-menu .menu ul.drop-list li:first-child a {
   border-radius : 5px 5px 0 0;
}

.full-menu .menu ul.drop-list li:last-child a {
  border-radius : 0 0 5px 5px;
}

.full-menu .menu ul.drop-list a.active {
  font-weight: bold;
  text-decoration:underline;
  color:#723AA8;
}
.menu a.active {
  font-weight: bold;
  text-decoration:underline;
  color:#723AA8;
  /*border-bottom: 2px solid #61dafb;*/
}

.full-menu .menu .hdr-contact-btn a {
  background-color: #723AA8;
  border:1px solid #723AA8;
  color:white;
}

.full-menu .menu .hdr-contact-btn a:hover {
  color: #723AA8;
  border:1px solid #723AA8;
  background-color: rgba(221, 202, 239, 0.50);;
}


.burger-icon-wrap {
  display: none; /* Hide burger menu by default */
  padding-left: 25px;
}

.burger-links { 
  display: none;
  background: white;
}

.burger-links li{ 
  padding: 10px;
}

.burger-menu .fa-bars {
  font-size: 24px; /* Adjust size as needed */
  cursor: pointer;
}

.burger-menu li {
  margin-top: 1rem;
}



/* Show burger menu on smaller screens */
@media (max-width: 768px) { 
  .full-menu {
    display: none; /* Hide full menu on smaller screens */
  }

  .burger-icon-wrap {
    /*display: block; 
    padding: 2rem;
    */
    display:flex;
    align-items: center;
  }

  .burger-links {
    display: flex;
  }
}

/* Burger Menu Links match text resizing of p */
.burger-links li a {
  font-size: 20px;
}

@media (max-width: 700px) {
  .burger-links li a {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .burger-links li a {
    font-size: 16px;
  }
}
