header {
	width:100%;
  /*background-color: #f8f9fa;*/ /* Background color for the header */
  /*padding-top:2rem;*/
}

.header-image {
  /*height: 25vh;*/ /* Optional: Set a maximum height to prevent the image from being too tall */
  width: 100%; /* Maintain aspect ratio */
  object-fit: cover; 
  border-radius: 8px; /* Optional: rounded corners */
  margin-right: 1em; /* Spacing between image and text */
}

.header-content {
  padding: 2rem; /* Adjust padding as needed */
  position: relative; /* Ensure the floated elements remain in the header */
  height: 75px; /* Set a fixed height for the header */
  display: flex; /* Use Flexbox to align content */
  justify-content: space-between; /* Space out the site branding and menu */
  align-items: center; /* Vertically center both site branding and menu */
  padding-bottom: 2vw;
}

.branding-wrap {
  /*padding-left:2rem;*/
  /*float: left;
  height: 75px;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.site-title {
  margin:0 0 .5rem 0;
  text-align: left;
  text-wrap:nowrap;
}

.tagline {
  margin:0px;
  text-align: left;
}

@media (max-width: 900px) {
  .site-title {
    font-size: 36px;
  }

  .tagline {
    font-size:18px;
  }

}

@media (max-width: 500px) {
  .site-title {
    font-size: 30px;
  }

  .tagline {
    font-size:14px;
  }

}

/* Header.css */
.logout-button {
  background: none;
  border: none;
  color: #007bff; /* Link color */
  text-decoration: underline;
  cursor: pointer;
  font: inherit; /* Inherit font from parent */
}

.logout-button:hover {
  color: #0056b3; /* Darker shade on hover */
  text-decoration: none;
}

.logout-button-wrap {
  display: flex;
  justify-content: flex-end;
}

li a {
  text-wrap: nowrap;
}
