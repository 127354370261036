.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  display: flex;
  flex-direction: column; /* Ensures header, scrollable content, and footer stack vertically */
  background: white;
  padding: 0; /* Remove padding here and adjust individually */
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  width: 90%;
  height: 90%;
  overflow: hidden; /* Ensure content stays inside the lightbox */
}

.lightbox-static-header {
  padding: 5px 20px;
  background: white;
  border-bottom: 1px solid #ddd;
  text-align: right; /* Align close button to the right */
}

.close {
  cursor: pointer;
  font-size: 24px;
}

.lightbox-scrollable {
  flex-grow: 1; /* Allows this area to grow and take up remaining space */
  overflow-y: auto; /* Enables scrolling in the middle section */
  padding: 20px; /* Add padding for content */
}

.lightbox-static-footer {
  padding: 5px 20px;
  background: white;
  border-top: 1px solid #ddd;
  text-align: center;
}

.lightbox-static-footer button {
  margin-top: 0px;
}

.done-button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.done-button:hover {
  background-color: #555;
}

/*.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  display: flex;
  flex-direction: column; 
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  width: 80%; 
  height: 80%; 
}

.lightbox-scrollable {
  flex-grow: 1; 
  overflow-y: auto; 
  margin-bottom: 10px;
}

.lightbox-image-container img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.lightbox-static-footer {
  position: relative; 
  text-align: center; 
}

.done-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.done-button:hover {
  background-color: #555;
}
*/