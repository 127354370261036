/* src/Components/PdfViewer.css */
/* src/Components/PdfConsentForm.css */
.pdf-consent-form {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.pdf-container {
  height: 800px;
  width: 100%;
  margin-top: 20px;
  position: relative; /* Ensure the viewer container does not affect the layout */
}

input[type="file"] {
  margin-bottom: 10px;
  display: block; /* Ensure the file input does not overlap other elements */
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: block; /* Ensure the button does not overlap other elements */
}

/* Adjust viewer plugin styles if needed */
.react-pdf-viewer__default-layout {
  display: flex;
  flex-direction: column;
}

.react-pdf-viewer__toolbar {
  display: flex;
  justify-content: space-between; /* Distribute buttons evenly */
}

.react-pdf-viewer__page-controls {
  display: flex;
}
