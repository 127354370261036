.qr-image {
	max-width:300px;
	width:100%;
}

.venmo-wrap {
	width: 100%;
	box-sizing: border-box;
	text-align: center;
}

.paypal-wrap {
	width: 100%;
	box-sizing: border-box;
	text-align: center;
}