html {
  height: 100%;
  width: 100%;
} 

body {
  height: 100%;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  /*overscroll-behavior: none; /*Stop scroll bounce*/
  background-color: #ffffff; /*#e4a5c4;*/ /*lavender;*/ /*#282c34;*/
}

body {

  /* Fixed position background circles */
  /*
  background: radial-gradient(circle at 10% 20%, #ff7e5f, transparent 50%),
    radial-gradient(circle at 80% 70%, #feb47b, transparent 50%);
  background-size: 200px 200px, 200px 200px;
  background-repeat: no-repeat, no-repeat;
  background-position: top left, bottom right;
  */

  /* Fixed Position Background Img */
  
  /*
  background-image: url(../public/charlie-brown.jpg);
  background-size: cover;
  background-position: center top;
  background-repeat: repeat-y;
  background-attachment: scroll;
  */
  


}

#root {
  /* Scrollable Background Img */
  
  background-image: url(../public/lavendar-background.png);
  background-size: 100% auto; 
  background-position: top;
  background-repeat: repeat-y;
  background-attachment: scroll;  
  min-height: 100vh; /* Ensure it takes at least the full viewport height */
  width:100%;
  box-sizing: border-box;
  min-width: 300px;
}

.App {
  text-align: center;
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.MainLayoutWrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black; 
  min-width: 300px;
}

.MainLayoutWrap p {
  font-size: 20px;
}

@media (max-width: 700px) {
  .MainLayoutWrap p {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .MainLayoutWrap p {
    font-size: 16px;
  }
}



.App-link {
  color: #61dafb;
}

/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather', 'Mulish', sans-serif;
}

h2 { 
  text-align:center; 
}

.ContentWrap {
  width: 100%;
  padding: 6vw;
  box-sizing: border-box;
  padding-top:20px;
}

.form-wrap {
  padding:0 10px;
}

.form-content-wrap {

}

.form-selection-wrap {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 10px; /* Space between buttons */
  margin-bottom: 20px;
}

.form-selection-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  width: 150px;
}

.form-selection-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly scale button */
}

.form-selection-button:active {
  background-color: #003d7a; /* Even darker shade on click */
  transform: scale(1); /* Reset scale */
}

input[type="submit"] {
  font-family: 'Mulish', sans-serif;

}

/* Home */
.h2-title {
  text-align: left;
  margin-bottom: 2rem;
}

.three-buttons {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  flex-wrap: wrap; /* Allow buttons to wrap to the next line if necessary */
  row-gap: 16px; /* Space between buttons */
  column-gap: 64px;
  margin-bottom: 64px;
}

.three-buttons a {
  text-decoration: none; /* No Underline */
}

.three-buttons button {
  display: flex;
  flex-direction: column; /* Stack the image and text vertically */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  width: 300px; /* Set width for buttons */
  height: 200px; /* Set height for buttons */
  padding: 16px; /* Add padding inside the button */
  border: none; /* Remove default border */
  background: #fff; /* Set background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
  text-align: center; /* Center text */
}

.three-buttons button:hover {
  background-color: #f0f0f0; /* Change background on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.three-buttons img {
  max-width: 80%; /* Ensure images fit within the button */
  height: 60px; /*auto;*/ /* Maintain aspect ratio */
  margin-bottom: 8px; /* Space between image and text */
}

.three-buttons h3 {
  margin: 0; /* Remove default margin */
  font-family: 'Mulish';
  font-size: 24px; /* Adjust font size if necessary */
  line-height: 1.4; /* Improve text readability */
  word-wrap: none; /* Wrap text if necessary */
}

@media (max-width: 500px) { 
  .three-buttons h3 {
    font-size: 20px; /* Adjust font size if necessary */
  }
}



.pic-text {
  display: flex;
  justify-content: center; /* Horizontally center the image and text */
  align-items: center; /* Vertically align the image and text */
  gap: 64px; /* Add space between the image and text */
  margin-bottom: 64px;
}

.lace-pic {
  width: 275px; /* Set a max width for the image */
  max-width: 80%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 275px;
  padding: 16px;
  background: #DDCAEF;
}

.pic-text-text {
  max-width: 500px; /* Set the maximum width for the text content */
  display: flex;
  flex-direction: column; /* Stack the heading and paragraphs vertically */
  justify-content: center; /* Vertically center text content */
  text-align: left; /* Keep text aligned to the left */
}

.pic-text-text h2 {
  margin: 0 0 1rem 0; /* Add space between the heading and first paragraph */
  font-size: 36px;
  font-style: italic;
}

.pic-text-text p {
  margin: 1rem 0 8px 0; /* Space out paragraphs */
  font-size:24px;
  line-height: 1.5;
}

.pic-text-text p:last-of-type {
  margin-bottom: 0; /* Remove margin on the last paragraph */
}

/* Responsive styling for smaller screens */
@media (max-width: 900px) {
  .pic-text {
    flex-direction: column; /* Stack image and text vertically */
    text-align: center; /* Center align the text */
    gap: 32px; /* Adjust spacing between the image and text */
  }

  .pic-text-text {
    max-width: 100%; /* Allow text to take full width */
    text-align: center; /* Center text when stacked */
  }

  .pic-text-text h2 {
    font-size: 24px; /* Reduce heading size on smaller screens */
  }

  .pic-text-text p {
    font-size: 20px;
    margin: 1.5rem 0 8px 0; /* Adjust margin for smaller screens */
  }
}

.about-me {
  margin-bottom: 64px;
}


.text-pic {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  gap: 64px; 
  margin-bottom: 64px;
  width: 100%; 
}

.text-pic img {
  border-radius: 25px;
  box-shadow: -15px -15px 0 1px #DDCAEF;
}

.our-process {
  max-width: 800px; 
}

.our-process p {
  margin-bottom: 16px; 
  line-height: 1.5;
}

.text-pic img {
  max-width: 300px; 
  width: 300px;
  height: auto; 
}

@media (max-width: 900px) {
  .text-pic {
    flex-direction: column; 
    align-items: center; 
    gap: 32px; 
  }

  .our-process {
    max-width: 100%; 
    text-align: left; 
  }

  .text-pic img {
    max-width: 100%;
  }
}

/* Contact Page */


/*
.header-image-wrap {
  width:100%;
  position: relative;
}

.header-text {
  position: absolute; 
  top: 50%;
  right: 10%; 
  transform: translateY(-50%); 
  color: white; 
  font-size: 2rem; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); 
}
*/
.header-img-wrap {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.header-image {
  /*width: 100%;*/
  height: 150px; /*auto; */
  display: block; /* Remove inline spacing below image */
}

.header-text {
  position: absolute;
  top: 30%;
  right: 10%;
  transform: translateY(-50%); /* Vertically center the text */
  color: white;
  font-size: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .header-text {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
    right: 5%; /* Shift text more towards the center */
  }
}


.centered {
  text-align: center;
}

/* Coaching */

.pic-text-2 {
  overflow: hidden; /* Ensure the container wraps around floated elements */
  margin-bottom: 32px;
  text-align: left;
 
}

.pic-text-2-text {

}

.pic-text-2-text h2 {
  margin-top: 0px;

}

.pic-text-2-pic {
  float: left; /* Float image to the left */
  margin-right: 32px; /* Add some spacing between the image and the text */
  margin-bottom:16px;
  max-width: 300px; /* Max width for the image */
  width: 100%; /* Ensure it takes the available width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width:750px) {
  .pic-text-2-pic {
    max-width: 250px;
  }
}

@media (max-width:550px) {
  .pic-text-2 {
    text-align: center;
  }
  .pic-text-2-pic {
    float:none;
    margin-right:0px;
  }
}

/* Workshops Page */

.workshops-list {
  display: flex;
  flex-direction: column;
  gap: 32px; /* Space between rows */
}

.auto-gen-workshops {
  display: flex;
  flex-direction: column;
  gap: 32px; /* Space between rows */
}

.static-workshops {
  display: flex;
  flex-direction: column;
  gap: 32px; /* Space between rows */
}



.workshops-row {
  display: flex;
  justify-content: center; /*flex-start;*/
  align-items: center;
  gap: 32px; /* Space between image and card */
  flex-wrap: wrap; /* Allow wrapping when too small */
}

.workshops-img {
  max-width: 350px;
  /*width: 100%;*/ /* Makes the image responsive */
  height: 245px; 
  object-fit: cover;
  border-radius: 16px;
}

.workshops-card {
  font-family: "Mulish";
  display: block;
  /*width: 100%;*/ /* Take full width by default */
  max-width: 600px;
  height: 193px; /*245 hgt of img - 52 for padding*/
  padding: 24px 32px;

  border-radius: 16px;
  border: 2px solid #EEE7F6;
  background: #F9F8FA;
  box-shadow: 8px 8px 4px 0px #EEE7F6;
  text-decoration: none;
  color:black;
}

.workshops-card:hover { 
  background-color: white;
  color:#723AA8;
}

.workshops-card:active { 
  color:black;
}


.workshops-title {
  margin-top:0px;
  margin-bottom:5px;
}

p.workshops-date{
  margin-top:0px;
  margin-bottom: 3px;
  font-size: 14px;
}

p.workshops-location {
  margin-top: 0px;
  font-size: 14px;
}

p.workshops-desc {
  font-size: 18px;
  margin-top:0px;
  margin-bottom: 0px;
}

p.workshops-cost { 
  font-weight: bold;
  margin-top: 10px;

}

/* Responsive styling for smaller screens */
@media (max-width: 700px) {
  .workshops-row {
    flex-direction: column;
    align-items: center; /* Center the image and card on small screens */
  }

  .workshops-card {
    height:auto;
  }

  .workshops-img {
    max-width: 100%; /* Image takes full width in small screens */
  }
}

/* Workshop Individual Page */

.workshop-header-wrap {
  display: flex;
  align-items: center; /*flex-start;*/ /* Align items at the top */
  justify-content: center;
  gap: 16px; /* Space between image and text container */
  padding: 16px; /* Padding around the container */
  flex-wrap: wrap; /* Allow wrapping when space is insufficient */
}

.workshop-header-img {
  max-width: 300px;/*100%;*/
  height: auto;
  border-radius: 8px;
}

.workshop-header-text {
  background-color: #DDCAEF; /* Background color for text container */
  padding: 16px 42px;
  border-radius: 8px;
  flex: 1; /* Allow text container to grow */
  max-width: 600px; /* Optional max width for larger screens */
}

.workshop-header-title { 
  text-align: left;
}

.workshop-cost {
  font-weight: bold;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .workshop-header-wrap {
    flex-direction: column; /* Stack image and text container vertically */
    align-items: center; /* Center align items when stacked */
  }
}

/* Image Gallery */

.gallery-images {
  display: flex; 
  flex-wrap: wrap; 
  align-items: center; 
  justify-content: center;
}

.gallery-images .img-wrap {
  width: 200px;
  height:200px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.gallery-images img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.pagination-bar {
  display: flex;
  align-items: center; /* Vertically centers the items */
  justify-content: center; /* Horizontally centers the items */
  gap: 10px; /* Adds some space between buttons and input */
  font-size: 14px;
  margin: 10px 0;
  flex-wrap: wrap;
}

.pagination-bar input[type="numeric"] {
  width: 20px;
  height: 14px;
  text-align: center;
  font-size: 14px;
  padding: 4px; /* Add padding for better spacing inside the input */

}

.pagination-bar button {
  display: flex; /* Makes it easier to center content */
  align-items: center; /* Vertically centers the content */
  justify-content: center;

  font-size: 14px;
  padding: 4px; /* Add padding for better spacing inside the input */
  margin: 0px;

}

.pagination-bar button svg {

}

.search-bar {
  display: flex;
  align-items: center; /* Vertically centers the items */
  justify-content: center; /* Horizontally centers the items */
  gap: 10px; /* Adds some space between buttons and input */
  font-size: 14px;
  margin: 10px 0;
  flex-wrap: wrap;

}

.search-bar input[type="text"] {
  width: 200px;
  font-size: 14px;
  padding: 4px; /* Add padding for better spacing inside the input */

}

.search-bar input[type="date"] {
  font-size: 14px;
  padding: 4px; /* Add padding for better spacing inside the input */
  margin: 0px;

}

.search-bar span {
  display: flex;
  align-items: center; /* Vertically center label and input */
  justify-content: center;
}

.search-bar input[type="submit"] {
  padding: 4px 16px; /* Add padding for the button */
  font-size: 14px; /* Ensure font size matches the rest of the form */
}

.edit-workshop .quill {
  margin-top:10px;
  margin-bottom:5px;
}

.edit-workshop .ql-toolbar {
  border-radius: 4px 4px 0px 0px; /* Slightly rounded corners */
  background-color: #DDCAEF;
}

.edit-workshop .ql-container {
  border-radius: 0px 0px 4px 4px; /* Slightly rounded corners */
  background-color:white;
}


.edit-workshop label {
  font-size: 18px;
  margin: 0px 0px 0px 0px;
}

.edit-workshop input {
  font-size: 14px;
  font-family: 'Merriweather', 'Mulish', sans-serif;
}

.edit-workshop input[type="submit"] {
}

.edit-workshop textarea {
  font-size: 14px;
  font-family: 'Merriweather', 'Mulish', sans-serif;

}

.edit-workshop input[name="workshopTitle"] {
  margin-top:15px;
}

.edit-workshop .quill p {
  font-size: 14px;
  font-family: 'Merriweather', 'Mulish', sans-serif;


}

.edit-workshop input[type="date"] {
  max-width: 150px;
}

.edit-workshop input[type="numeric"] {
  max-width: 100px;
}

.edit-workshop select {
  margin-right: 10px;
}

.edit-workshop .short-ans-wrap {
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex; /* This ensures all inputs are horizontally aligned */
  align-items: center; 
  gap: 10px;

}

.edit-workshop .short-ans-wrap input {
  margin: 0px 10px;
  height: 30px;
  padding: 0px 10px;
  box-sizing: border-box; /* Ensures padding doesn’t affect the height */
  border: 1px solid #ccc;
  border-radius: 4px;

}

.edit-workshop .submit-wrap {
  width: 100%;
}


.edit-workshop .submit-wrap input.delete-workshop-btn {
  float: right;
}


.edit-workshop .selects-wrap {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex; /* This ensures all inputs are horizontally aligned */
  align-items: center; 
  gap: 10px;
}

.edit-workshop .selects-wrap select {
  background-color: #DDCAEF;
  border-radius: 4px;
  border:none;
  padding: 3px 10px;
  font-weight: bold;
  font-family: 'Merriweather', 'Mulish', sans-serif;
}