/* ./Form.css */

h1 {
  font-weight: bold;
  color:black;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
  padding: 1em;
  border-radius: 16px;
  /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
  background-color: transparent; /*#f9f9f9;*/
  border: 4px solid #DDCAEF;
}

.MainLayoutWrap form p {
  display: inline;
  font-size:14px;
}

.form-label {
  margin-top:.5em;
  margin-bottom:.5em;
}

.form-style {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

label {
  margin-bottom: 0.5em;
  font-weight: bold;
  color:black;
  text-align: left;
}

input[type="text"] {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}

input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

input[type="email"] {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}

input[type="email"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

input[type="number"] {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}

input[type="number"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px 
}

input[type="tel"] {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}

input[type="tel"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px 
}

input[type="date"] {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
}

input[type="date"]:focus {
  /*border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px */
}

input[type="password"] {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  resize:vertical;
}

textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}

input[type="submit"] {
  padding: 0.7em;
  border: 1px solid #DDCAEF;;
  border-radius: 5px;
  color: #723AA8; /*#fff;*/
  background-color: #DDCAEF; /*#007bff;*/
  cursor: pointer;
  font-size: 1em;
}

input[type="submit"]:hover {
  background-color: white; /*#0056b3;*/
  border-color: #723AA8;
}

.form-label-fa-symbol {
  padding-right: 10px;
}

.recaptcha-wrap {
  margin-bottom: 0.5em;
  text-align: center;
}

.recaptcha-wrap div div div {
  display: inline-block;
}

.MainLayoutWrap form p.form-sub-instruct {
  display: inline-block;
  font-weight: normal;
  font-style: italic;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.form-label {
  margin-bottom: 0px;
}

.form-error-text {
  color: red;
  font-style: italic;
  font-weight: normal;
  margin-block-start: .25em;
  margin-block-end: .25em;
}

.MainLayoutWrap .form-error-text {
  font-size: 14px;
}



#rc-anchor-container {}

@media screen and (max-width: 410px) { 
  .recaptcha-wrap iframe {
    transform: scale(0.90);
    transform-origin: 0 0;
    -webkit-transform: scale(0.90);
    -webkit-transform-origin: 0 0;
  }
}

@media screen and (max-width: 370px) { 
  .recaptcha-wrap iframe {
    transform: scale(0.80);
    transform-origin: 0 0;
    -webkit-transform: scale(0.80);
    -webkit-transform-origin: 0 0;
  }
}

@media screen and (max-width: 335px) { 
  .recaptcha-wrap iframe {
    transform: scale(0.70);
    transform-origin: 0 0;
    -webkit-transform: scale(0.70);
    -webkit-transform-origin: 0 0;
  }
}

.checkbox-container {
  display: flex;
  align-items: center; /* Perfectly centers the checkbox and label */
}

.checkbox-container label {
  margin-left: 8px; /* Adds space between the checkbox and the label */
  font-size: 16px; /* Ensures the text is consistent */
}

.checkbox-container input {
  display: inline-block;
  vertical-align: middle;
}
.checkbox-container label {
  margin-bottom: 0px;
}
